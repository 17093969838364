<template>
  <div class="qr">
    <div class="close-wrap">
      <IconClose class="icon-close" @click="close" />
    </div>
    <div class="qr-wrap" v-html="svg"></div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import QRCode from "qrcode-svg"
import IconClose from "@/assets/icon-close.svg"

export default {
  name: "QR",
  mixins: [mixinGlobal],
  props: {
    partner: {
      type: Object,
    },
    client: {
      type: Object,
    },
  },
  components: {
    IconClose,
  },
  data() {
    return {
      svg: null,
    }
  },
  computed: {},
  created() {
    this.renderSVG()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    renderSVG() {
      this.svg = new QRCode({
        content: this.partner.ethAddress,
        padding: 0,
        width: 100,
        height: 100,
        color: "#000000",
        background: "transparent",
        ecl: "M", // L | M | H | Q // error correction level
        join: true,
        predefined: false,
        pretty: false,
        swap: false,
        xmlDeclaration: false,
        container: "svg-viewbox",
      }).svg()
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="less">
.qr {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: var(--color-qr-bg);
  border-radius: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center;

  .qr-wrap {
    width: calc(100vh - 70px);
    height: calc(100vh - 70px);
    align-items: flex-start;
    margin-top: 12px;

    // @media screen and (min-width: 365px) {
    //   width: 200px;
    //   height: 200px
    // }

    svg {
      path {
        fill: var(--color-qr-code) !important;
      }
    }
  }

  .close-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .icon-close {
      width: 30px;
      height: 30px;
      margin: 20px 20px 0 0;
      cursor: pointer;

      * {
        fill: var(--color-qr-icon-close);
      }

      &:hover {
        * {
          fill: var(--color-qr-icon-close-hover);
        }
      }

      &:active {
        * {
          fill: var(--color-qr-icon-close-active);
        }
      }
    }
  }
}
</style>
